<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How engaging did you find the format and style of the instructional videos?
      </p>
      <p class="mb-2">Likert Scale (1 - Not Engaging, 5 - Very Engaging)</p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Do you feel the videos covered each lab technique's most important aspects?
      </p>
      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options2"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
      <s-textarea v-model="inputs.input3" outlined />

      <p class="mb-2">
        Would you recommend these instructional videos to other students learning these lab
        techniques?
      </p>
      <v-radio-group v-model="inputs.input4" :disabled="!allowEditing" class="mb-2 ml-6">
        <v-radio
          v-for="option in options2"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
      <s-textarea v-model="inputs.input5" outlined />

      <p class="mb-2">
        How frequently did you need to pause, rewind, or re-watch parts of the videos to understand
        the content fully?
      </p>
      <v-radio-group v-model="inputs.input6" :disabled="!allowEditing" class="mb-3 ml-6">
        <v-radio
          v-for="option in options3"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        What improvements, if any, would you suggest for future instructional videos?
      </p>
      <s-textarea v-model="inputs.input7" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissLab9VideoResponsesConclusion',
  components: {
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      options1: [
        {
          text: '1',
          value: 'one',
        },
        {
          text: '2',
          value: 'two',
        },
        {
          text: '3',
          value: 'three',
        },
        {
          text: '4',
          value: 'four',
        },
        {
          text: '5',
          value: 'five',
        },
      ],
      options2: [
        {text: 'Yes - Please explain why this is your answer.', value: 'yes'},
        {text: 'No - Please explain why this is your answer.', value: 'no'},
      ],
      options3: [
        {text: 'Never', value: 'never'},
        {text: 'Occasionally', value: 'occasionally'},
        {text: 'Frequently', value: 'frequently'},
        {text: 'Every Video', value: 'every'},
      ],
    };
  },
};
</script>
<style scoped></style>
